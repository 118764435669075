import React, { useRef } from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import emailjs from '@emailjs/browser';
function Contact() {

    const form = useRef();
    const sendEmail = (e) => {
      e.preventDefault();
      emailjs.sendForm('service_hqhrtuf', 'template_puy2g3s', form.current, {
        publicKey: 'Ab7GIbLNKZQNq9s7a',
      })
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
        e.target.reset();
    };
  return (
    <section id='contact' className='hidden'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className='container contact__container'>
      <div className='contact__options'>
      <article className='contact__option'>
      <MdOutlineEmail className='contact__option-icon'/>
      <h4>Email</h4>
      <h5>alidazx@gmail.com</h5>
      <a href='mailto:alidazx@gmail.com' target='_blank' rel="noreferrer">Send a message</a>
      </article>
      <article className='contact__option'>
      <RiMessengerLine className='contact__option-icon'/>
      <h4>Messenger</h4>
      <h5>REZZI</h5>
      <a href='https://m.me/alireza.dehghanazad' target='_blank' rel="noreferrer">Send a message</a>
      </article>
      <article className='contact__option'>
      <BsWhatsapp className='contact__option-icon'/>
      <h4>Whatsapp</h4>
      <h5 id='phone'>+491788900334</h5>
      <a href='https://api.whatsapp.com/send?phone+491788900334' target='_blank' rel="noreferrer">Send a message</a>
      </article>
      </div>
      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name='name' placeholder='Your Full Name' required />
        <input type="email" name='email' placeholder='Your Email' required />
        <textarea name="message" rows='7' placeholder='Your Message' required ></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
      </form>
      </div>
    </section>
  )
}

export default Contact

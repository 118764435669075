import React, { useEffect, useRef } from 'react';
import './header.css'
import CTA from './CTA'
import ME from '../../assets/me.png'
import HeaderSocials from './HeaderSocials'
import { TbArrowBigDownLineFilled } from "react-icons/tb";
import { gsap } from "gsap"
import SplitType from 'split-type'

function Header() {
  const textRefs = useRef([useRef(null), useRef(null), useRef(null)]);

  const animateText = (ref) => {
    const splitText = new SplitType(ref.current, { type: 'chars' });
    gsap.to(splitText.chars, {
      y: 0,
      stagger: 0.05,
      delay: 0.2,
      duration: 0.1,
      ease: 'power2.out',
    });
  };

  useEffect(() => {
    textRefs.current.forEach((ref) => animateText(ref));
  }, []);
  

  return (
    <header >
    <div className="container header__container">
    <h5 id='myText' ref={textRefs.current[0]}>Hello I'm</h5>
        <h1 id='myText' ref={textRefs.current[1]}>Alireza Dehghanazad</h1>
        <h5 id='myText' className='text-light' ref={textRefs.current[2]}>Fullstack Developer</h5>
 
        <CTA/>
        <HeaderSocials/>
        <div className='me'>
            <img src={ME} alt='me' />
        </div>
        <a href="#contact" className='scroll__down'>Scroll Down
        <TbArrowBigDownLineFilled style={{ transform: 'rotate(-90deg)', margin: '1px 1rem -3px 1rem' }} />

        </a>
     
    </div>
    </header>
  )
}

export default Header


import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import axios from 'axios'; 
function CTA() {
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState(false);
 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPassword(''); 
    setError(false); 
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError(false); 
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    if (password === process.env.REACT_APP_API_PASSWORD) {
      try {
        const response = await axios.get(process.env.REACT_APP_API_LINK);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'cv.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        console.error('Fehler beim Herunterladen der Datei:', error);
      }
      handleClose();
    } else {
      setError(true);
    }
  };
  
  return (
    <React.Fragment >
   
      <div className='cta'>
        <a onClick={handleClickOpen} className='btn'>Download CV</a>
        <a href="#contact" className='btn btn-primary'>Let's Talk </a>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        {/* <DialogTitle>Download CV</DialogTitle> */}
        <DialogContent >
          <DialogContentText>
          Please enter the password to download the resume:
          </DialogContentText>
          <form onSubmit={handleSubmit}>
            <TextField
              autoFocus
              required
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              value={password}
              onChange={handlePasswordChange}
            />
            {error && <p style={{ color: 'red' }}>The entered password is incorrect. Please try again.</p>}
            <DialogActions>
              <Button onClick={handleClose} >Cancel</Button>
              <Button type="submit" >Download</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
  
  
}

export default CTA

import ReactDOM from "react-dom";
import React, { useEffect } from "react";
import App from "./App.jsx";
import "./index.css";

const Root = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));

    // Cleanup function to disconnect the Intersection Observer when component unmounts
    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []); // Empty dependency array ensures that this effect runs only once after initial render

  return <App />;
};

ReactDOM.render(<Root />, document.querySelector("#root"));

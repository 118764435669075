import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.png' 
import IMG2 from '../../assets/portfolio2.png' 
import IMG3 from '../../assets/portfolio4.jpg' 
import IMG0 from '../../assets/portfolio0.png' 


const data = [
  {
    id:1,
    image:IMG0,
    titel: 'Art Gallery: Auth, Authz, Secure Login & Reviews – Powered by PHP & Bootstrap',
    github:'https://github.com/REZZI-DA/art-gallary/',
    demo:'https://rezzidevlab.com/art-gallary/pages/home.php'
  },
  {
    id:2,
    image:IMG1,
    titel: 'A game called Alien Defense',
    github:'https://github.com/REZZI-DA/-Java',
    demo:'https://github.com/REZZI-DA/-Java'
  },
  {
  id:3,
  image:IMG2,
  titel: 'FilmFusion A website using ASP.NET for renting movies ',
  github:'https://github.com/REZZI-DA/ASP.NET',
  demo:'https://github.com/REZZI-DA/ASP.NET'
  },
  {
  id:4,
  image:IMG3,
  titel: 'A Restaurant App with using Asp.Net Core',
  github:'',
  demo:''
  },



]
function Portfolio() {
  
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
        <div className='container portfolio__container'>
      {

        data.map(({id,image,titel,github,demo}) => 
        {
          return (
      <article key={id} className='portfolio__item'>
        <div className='portfolio__item-image'>
          <img src={image} alt= {titel} />
        </div>
        <h3>{titel} </h3>
        <div className='portfolio__item-cta'>
        <a href={github} className='btn'>Github</a>
        <a href={demo} className='btn btn-primary' target='_blank'  rel="noreferrer">Live Demo</a>
        </div>
      </article>
          )
        })
      }
      </div>
    </section>
  )
}

export default Portfolio

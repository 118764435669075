import React from 'react'
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
import { FiDribbble } from "react-icons/fi";
function HeaderSocials() {
  return (
   
    <section className='header__Socials'>
      <a href="https://linkedin.com/in/alireza-dehghanazad-49a153216" target='_blank' rel="noreferrer"  ><BsLinkedin/></a>
      <a href="https://github.com/REZZI-DA" target='_blank' rel="noreferrer" ><FaGithub/></a>
      <a href="https://dribbble.com/REZZI-DA" target='_blank' rel="noreferrer" ><FiDribbble/></a>
    </section>
  
  )
}

export default HeaderSocials

import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import AVTR4 from '../../assets/avatar4.jpg'


import { Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';


import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
  avater:AVTR1,
  name: 'John Doe',
  review:"I'm impressed by the portfolio designs showcased on this website. Each project demonstrates creativity and attention to detail. Highly recommended for anyone looking for inspiration!",
  },
  {
    avater:AVTR2,
    name: 'Emma White',
    review:'As a potential client, I found this portfolio website very informative and visually appealing. It helped me understand the skills and expertise of the creator. Great job!',
  },
  {
    avater:AVTR3,
    name: 'John Doe',
    review:"The portfolio website is well-organized and easy to navigate. I particularly enjoyed the variety of projects showcased. It's evident that the creator is talented and versatile.",
  },
  {
    avater:AVTR4,
    name: 'Daniel Lee',
    review:"I stumbled upon this portfolio website while searching for design inspiration, and I'm glad I did. The projects displayed here are of high quality and reflect the creator's passion for their craft. Kudos!",
  },

]
function Testimonials() {
  return (
    <section id='testimonials' className='hidden'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>
      <Swiper className='container testimonials__container'
      modules={[ Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>
    
      {
      data.map(({avater,name,review},index) => 
      {
        return (
          <SwiperSlide key={index} className='testimonial'>
          <div className='client__avater'>
          <img src= {avater} alt=''/>
          </div>
          <h5 className='client__name'>{name}</h5>
          <small className='client__review'>
          {review}
          </small>
        </SwiperSlide>
        )
      })
      }

      </Swiper>
    </section>
  )
}

export default Testimonials

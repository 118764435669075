import React from "react";
import "./services.css";
import { BiCheck } from "react-icons/bi";
function Services() {
  return (
    <section id="services" className="hidden"> 
      <h5>What I Offer</h5>
      <h2>Servieces</h2>
      <div className="container services__container">
      <article className="service">
        <div className="service__head">
          <h3>.NET Development</h3>
        </div>
        
        <ul className="service__list">
          <li>
            <BiCheck className="service__list-icon" />
            <p>Expertise in building RESTful APIs using ASP.NET Core for seamless communication between frontend and backend systems.</p>
          </li>
          <li>
            <BiCheck className="service__list-icon" />
            <p>Experience in implementing authentication and authorization mechanisms, such as JWT, to ensure secure access to web applications.</p>
          </li>
          <li>
            <BiCheck className="service__list-icon" />
            <p>Proficient in using .NET libraries and frameworks to streamline development processes and enhance application functionality.</p>
          </li>
          <li>
            <BiCheck className="service__list-icon" />
            <p>Strong troubleshooting skills to debug and resolve issues in .NET applications effectively.</p>
          </li>
          <li>
            <BiCheck className="service__list-icon" />
            <p>Experience with continuous integration and deployment (CI/CD) pipelines to automate the build, test, and deployment processes.</p>
          </li>
        </ul>
      </article>

      <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>
          
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Expertise in crafting intuitive user interfaces with a focus on user experience.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Proficiency in creating responsive and visually appealing designs using CSS, HTML, and frameworks like Vue.js.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Familiarity with integrating UI designs seamlessly with backend technologies like ASP.NET Core and .NET Framework.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Ability to collaborate with development teams to ensure the implementation of designs aligns with user interface and experience goals.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Basic understanding of React to broaden skillset and adapt to diverse project requirements.</p>
            </li>
          </ul>
        </article>

        <article className="service">
  <div className="service__head">
    <h3>Database Management</h3>
  </div>
  <ul className="service__list">
    <li>
      <BiCheck className="service__list-icon" />
      <p>Understand the fundamentals of relational databases and SQL.</p>
    </li>
    <li>
      <BiCheck className="service__list-icon" />
      <p>Query and retrieve data from single and multiple database tables.</p>
    </li>
    <li>
      <BiCheck className="service__list-icon" />
      <p>Use filtering, sorting, and aggregating techniques to manipulate data.</p>
    </li>
    <li>
      <BiCheck className="service__list-icon" />
      <p>Create and modify database tables, views, and indexes.</p>
    </li>
    <li>
      <BiCheck className="service__list-icon" />
      <p>Implement data integrity constraints and ensure data consistency.</p>
    </li>

    <li>
      <BiCheck className="service__list-icon" />
      <p>Write efficient and optimized SQL queries for improved performance.</p>
    </li>
    <li>
      <BiCheck className="service__list-icon" />
      <p>Design databases effectively, including database schema and normalization principles.</p>
    </li>
    <li>
      <BiCheck className="service__list-icon" />
      <p>Secure your databases.</p>
    </li>
  </ul>
</article>

      </div>
    </section>
  );
}

export default Services;
